<template>
	<div class="aboutDetails">
		<moduleAll v-for="(item,index) in nowData.templatees" :key="index" :templates="item"></moduleAll>
		<contact></contact>
	</div>
</template>

<script>
	import contact from '@/components/contact.vue'
	import moduleAll from '@/components/moduleAll.vue'
	import $ from 'jquery'
	export default {
		name: 'thridpage',
		components: {
			moduleAll,
			contact
		},
		data(){
			return {
				id:'',
				btnText:'MORE +',
				nowData:{
					top_image:{
						url:''
					},
					templatees:[]
				},
				likeList:[]
			}
		},
		mounted() {
			let _this = this;
			_this.id = _this.$route.query.id;
			_this.getDataList(_this.id);
		},
		methods:{
			getDataList(id){
				let _this = this;
				_this.$axios({
				  method: 'get',
				  url: 'page_manager/thrid_page?id='+id,
				}).then(function(res) {
				  console.log(res);
				  if(res.data.data.length > 0){
					  _this.nowData = res.data.data[0];
				  }
				});
			},
		}
	}
</script>

<style>
</style>
