<template>
	<div class="home">
		<!-- 首屏 -->
		<div class="home_main">
			<p class="home_main_title" id="home_main_title"><span>{{ formData.name1 }}</span>{{ formData.name2 }}</p>
			<p class="home_main_title">{{ formData.name3 }}</p>
<!-- 			<p class="home_main_slogin margin_top">想象力</p>
			<p class="home_main_slogin">创造美好世界</p> -->
			<p class="home_main_slogin word word--magneto margin_top">
				<span class="letter1">
					<svg width="100px" height="150px" viewBox="0 0 100 150" preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>想</span>
				</span>
				<span class="letter2">
					<svg width="100px" height="150px" viewBox="0 0 100 150"
						preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>象</span>
				</span>
				<span class="letter3">
					<svg width="100px" height="150px"
						viewBox="0 0 100 150" preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>力</span>
				</span>
			</p>
			<p class="home_main_slogin word word--magneto">
				
				<span class="letter4">
					<svg width="100px" height="150px"
						viewBox="0 0 100 150" preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>创</span>
					</span>
				<span class="letter5">
					<svg width="100px" height="150px"
						viewBox="0 0 100 150" preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>造</span>
				</span>
				<span class="letter6">
					<svg width="100px" height="150px"
						viewBox="0 0 100 150" preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>美</span>
				</span>
				<span class="letter7">
					<svg width="100px" height="150px"
						viewBox="0 0 100 150" preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>好</span>
				</span>
				<span class="letter6">
					<svg width="100px" height="150px"
						viewBox="0 0 100 150" preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>世</span>
				</span>
				<span class="letter7">
					<svg width="100px" height="150px"
						viewBox="0 0 100 150" preserveAspectRatio="xMaxYMax meet">
						<circle cx="11" cy="139" r="11"></circle>
						<circle cx="50" cy="139" r="11"></circle>
						<circle cx="89" cy="139" r="11"></circle>
					</svg>
					<span>界</span>
				</span>
			</p>
		</div>
		<!-- Swiper -->
		<swiper :banners="banners" id="swiper"></swiper>
		<!-- direction -->
		<div class="direction">
			<p class="direction_title" id="direction_title">项目方向</p>
			<direction :directionList="formData.directionList"></direction>
		</div>
		<!-- about -->
		<div class="about" id="about" @click="toPath('about')">
			<p class="about_title" >关于我们</p>
			<p class="about_message">{{ formData.about.contactUs }}</p>
		</div>
		<contact></contact>
	</div>
</template>

<script>
	// @ is an alias to /src
	import contact from '@/components/contact.vue'
	import direction from '@/components/direction.vue'
	import swiper from '@/components/swiper.vue'
	import $ from 'jquery'
	import Swiper from 'swiper'
	const charming = require('charming');
	import anime from '../assets/js/anime.min.js';
	export default {
		name: 'Home',
		components: {
			contact,
			direction,
			swiper
		},
		data() {
			return {
				slogan1Arr:[],
				slogan2Arr:[],
				formData:{
					about:{
						contactUs:''
					}
				},
				banners:[],
			}
		},
		mounted() {
			let _this = this;
			_this.getData();
		},
		methods: {
			getData(){
				let _this = this;
				_this.$axios({
					method: 'get',
					url: 'page_manager/first_page',
				}).then(function(res) {
					if(res.data.code == 0){
						_this.formData = res.data.data[0].body;
						_this.banners = res.data.data[0].banners;
						_this.slogan1Arr = res.data.data[0].body.slogan1.split('');
						_this.slogan2Arr = res.data.data[0].body.slogan2.split('');
						_this.aSlogan();
					}
					
				})
			},
			toPath(path) {
				this.$router.push({
					path: path
				})
			},
			aSlogan(){
				const getMousePos = (ev) => {
					let posx = 0;
					let posy = 0;
					if (!ev) ev = window.event;
					if (ev.pageX || ev.pageY) 	{
						posx = ev.pageX;
						posy = ev.pageY;
					}
					else if (ev.clientX || ev.clientY) 	{
						posx = ev.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
						posy = ev.clientY + document.body.scrollTop + document.documentElement.scrollTop;
					}
					return { x : posx, y : posy };
				};
				
				class Letter {
					constructor(letter) {
						this.DOM = {};
						this.CONFIG = {
							color: '#fff',
							trailDelay: 0,
							maxScaleX: 1,
							minScaleX: 0.9,
							maxScaleY: 1.3,
							minScaleY: 1,
							stretchTransition: 'transform 0.4s cubic-bezier(0.1,1,0.3,1)',
							reverseAnim: {
								duration: 1000,
								easing: 'easeOutElastic',
								elasticity: 600,
								scaleY: 1,
								scaleX: 1
							}
						};
						this.DOM.letter = letter;
						this.layout();
						this.initEvents();
					}
					layout() {
						this.DOM.letterInner = document.createElement('span');
						this.DOM.letterInner.innerHTML = this.DOM.letter.innerHTML;
						this.DOM.letter.innerHTML = '';
						this.DOM.svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
						this.DOM.svg.setAttribute('width', '100px');
						this.DOM.svg.setAttribute('height', '150px');
						this.DOM.svg.setAttribute('viewBox', '0 0 100 150');
						this.DOM.svg.setAttribute('preserveAspectRatio', 'xMaxYMax meet');
				
						const r = 11;
						for (let i = 0; i < 3; i++) {
							const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
							circle.setAttribute('cx', 39*i+r);
							circle.setAttribute('cy', 139);
							circle.setAttribute('r', r);
							this.DOM.svg.appendChild(circle);
						};
						this.DOM.circles = Array.from(this.DOM.svg.querySelectorAll('circle'));
						this.DOM.letter.appendChild(this.DOM.svg);
						this.DOM.letter.appendChild(this.DOM.letterInner);
					}
					stretch(ev) {
						const mousepos = getMousePos(ev);
						const docScrolls = {left : document.body.scrollLeft + document.documentElement.scrollLeft, top : document.body.scrollTop + document.documentElement.scrollTop};
						const bounds = this.DOM.letter.getBoundingClientRect();
						const relmousepos = { 
							x : mousepos.x - bounds.left - docScrolls.left, 
							y : mousepos.y - bounds.top - docScrolls.top 
						};
						anime.remove(this.DOM.letterInner);
						this.DOM.letterInner.style.transformOrigin = '50% 100%';
						const sX = (this.CONFIG.maxScaleX-this.CONFIG.minScaleX)/bounds.height * relmousepos.y + this.CONFIG.minScaleX;
						const sY = (this.CONFIG.minScaleY-this.CONFIG.maxScaleY)/bounds.height * relmousepos.y + this.CONFIG.maxScaleY;
						this.DOM.letterInner.style.transform = `scaleX(${sX}) scaleY(${sY})`;
					}
					initEvents() {
						this.mouseenterFn = () => this.mouseTimeout = setTimeout(() => {
							this.isActive = true;
							requestAnimationFrame(() => this.DOM.letterInner.style.transition = this.CONFIG.stretchTransition);
						}, 50);
						
						this.mousemoveFn = (ev) => {
							if( !this.isActive ) return;
							requestAnimationFrame(() => this.stretch(ev))
						};
						this.mouseleaveFn = () => {
							clearTimeout(this.mouseTimeout);
							if( !this.isActive ) return;
							this.isActive = false;
							this.DOM.letterInner.style.transition = 'none';
							requestAnimationFrame(() => {
								const scaleYCurrent = anime.get(this.DOM.letterInner, 'scaleY');
								anime.remove(this.DOM.letterInner);
								let animOpts = {targets: this.DOM.letterInner};
								anime(Object.assign(animOpts, this.CONFIG.reverseAnim));
				
								if( scaleYCurrent > 1.2 ) {
									anime.remove(this.DOM.circles);
									anime({
										targets: this.DOM.circles,
										duration: (t,i) => { return anime.random(300,400); },
										easing:'cubicBezier(0.1,1,0.3,1)',
										delay: (t,i) => { return i*40+parseInt(this.CONFIG.trailDelay); },
										opacity: [
											{value: 1, duration: 10, easing: 'linear'},
											{value: 0, duration: 200, easing: 'linear'}
										],
										translateY: (t,i) => { return [100,anime.random(-250,-120)]; },
										scaleX: [2,0.3],
										scaleY: [2,2]
									});
								}
							});
						};
						this.DOM.letter.addEventListener('mouseenter', this.mouseenterFn);
						this.DOM.letter.addEventListener('mousemove', this.mousemoveFn);
						this.DOM.letter.addEventListener('mouseleave', this.mouseleaveFn);
						this.DOM.letter.addEventListener('touchstart', this.mouseenterFn);
						this.DOM.letter.addEventListener('touchend', this.mouseleaveFn);
					}
				}
				
				class Word {
					constructor(word) {
						this.DOM = {};
						this.DOM.word = word;
						this.layout();
					}
					layout() {
						charming(this.DOM.word, {classPrefix: 'letter'});
						Array.from(this.DOM.word.querySelectorAll('span')).forEach(letter => new Letter(letter));
					}
				}
				Array.from(document.querySelectorAll('.word')).forEach((word) => new Word(word));
			},
		}
	}
</script>
<style scoped>
	@import url("../assets/css/magneto.css");
	.about{
		cursor: pointer;
	}
</style>
