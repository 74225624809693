<template>
	<div class="thridpage">
		<div class="swiper_box" v-if="uploadFlag" :style="'background-image: url(../assets/img/bg.png),url('+ nowData.top_image.url +');background-repeat:no-repeat;background-size:100% 100%;'">
			<p class="swiper_box_title">{{ nowData.title }}</p>
			<p class="swiper_box_message">{{ nowData.projectIntroduction }}</p>
		</div>
		<div class="thridpage_text" :style="'background-color: '+nowData.detailsColor">
			<p class="thridpage_text_title" :style="'color: '+nowData.titleColor">{{ nowData.title }}</p>
			<div class="introduction1" v-html="nowData.detailsHtml1">{{ nowData.detailsHtml1 }}</div>
			<div class="introduction2" v-html="nowData.detailsHtml2">{{ nowData.detailsHtml2 }}</div>
			<p class="more_btn" :style="'color: '+nowData.titleColor" @click="showMore">{{ btnText }}</p>
		</div>
		<moduleAll v-for="(item,index) in nowData.templatees" :key="index" :templates="item"></moduleAll>
		<!-- 推荐 -->
		<div class="recommend">
			<div class="recommend_box" v-for="item in likeList" :key="item.id" @click="toT(item.id)" :style="'background-image: url('+ item.top_image.url +');'">
				<p class="recommend_box_title">{{ item.title }}</p>
				<p class="recommend_box_conent">{{ item.projectIntroduction }}</p>
			</div>
		</div>
		<contact></contact>
	</div>
</template>

<script>
	import contact from '@/components/contact.vue'
	import moduleAll from '@/components/moduleAll.vue'
	import $ from 'jquery'
	export default {
		name: 'thridpage',
		components: {
			moduleAll,
			contact
		},
		watch:{
			$route(to,from){
				let _this = this;
			  if(from.path == '/thridpage' && to.path == '/thridpage'){
				_this.getDataList(to.query.id);
			  }else{
				  console.log('不是二级页跳转二级页')
			  }
			
			}
		},
		data(){
			return {
				id:'',
				btnText:'MORE +',
				nowData:{
					top_image:{
						url:''
					},
					templatees:[]
				},
				likeList:[],
				uploadFlag:false,
			}
		},
		mounted() {
			let _this = this;
			_this.id = _this.$route.query.id;
			console.log('刷新了')
			_this.getDataList(_this.id);
		},
		methods:{
			getDataList(id){
				let _this = this;
				_this.$axios({
				  method: 'get',
				  url: 'page_manager/thrid_page?id='+id,
				}).then(function(res) {
				  console.log(res);
				  if(res.data.data.length > 0){
					  _this.nowData = res.data.data[0];
					  _this.uploadFlag = true;
				  }
				});
				_this.$axios({
				  method: 'get',
				  url: 'page_manager/thrid_page_guess_like?curr_id='+id,
				}).then(function(res) {
				  console.log(res);
				  if(res.data.code == 0){
					_this.likeList = res.data.data;
				  }
				});
			},
			showMore(){
				let _this = this;
				if(_this.btnText == 'MORE +'){
					_this.btnText = 'LESS ×'
					$('.more_btn span').addClass('act');
				}else{
					_this.btnText = 'MORE +'
					$('.more_btn span').removeClass('act');
				}
				$('.introduction2').slideToggle('5000','linear')
			},
			toT(id){
				console.log(id);
				this.$router.push({path:'/thridpage',query:{id:id}});
				// location.reload()
				// window.open("/#/thridpage?id="+id);
			}
		}
	}
</script>

<style scoped="scoped">
	.recommend{
		width: 100%;
		overflow: hidden;
	}
	.recommend_box{
		display: flex;
		flex-direction:column;
		justify-content:center;
		width: 50%;
		height: 4.86rem;
		padding: 0 0.8rem;
		color:#fff;
		box-sizing: border-box;
		float: left;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		cursor: pointer;
	}
	.recommend_box_title{
		font-size: 0.56rem;
		font-family: SemiBold;
		line-height: 0.8rem;
		margin-bottom:0.22rem;
	}
	.recommend_box_conent{
		font-size: 0.32rem;
		font-family: Regular;
		line-height: 0.5rem;
	}
	.thridpage_text{
		width: 100%;
		min-height: 9.08rem;
		box-sizing: border-box;
		padding:2.14rem 1.54rem 1.54rem 1.54rem;
		
	}
	.thridpage_text_title{
		font-size: 1.08rem;
		font-family: Medium;
		line-height: 1.54rem;
	}
	.introduction1,.introduction2{
		width: 100%;
		font-size: 0.4rem;
		font-family: Regular;
		line-height: 0.64rem;
	}
	.introduction2{
		display: none;
		overflow: hidden;
	}
	@media screen and (min-width:751px){
		.thridpage_text{
			min-height: 9.84375rem;
			padding:3rem 0.84375rem 1.5rem 0.84375rem;
		}
		.thridpage_text_title{
			font-size: 0.703125rem;
			line-height: 1.484375rem;
		}
		.introduction1,.introduction2{
			font-size: 0.234375rem;
			line-height: 0.351562rem;
		}
		.more_btn{
			font-size: 0.234375rem;
			font-family: Regular;
			cursor: pointer;
			margin-top:0.2rem;
		}
		.recommend_box{
			height: 4.515625rem;
			padding: 0 0.59375rem;
		}
		.recommend_box_title{
			font-size: 0.46875rem;
			line-height: 0.671875rem;
			margin-bottom:0.15625rem;
		}
		.recommend_box_conent{
			font-size: 0.15625rem;
			line-height: 0.3rem;
		}
	}
	
</style>
