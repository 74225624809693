<template>
  <div id="app">
	<div class="mobile_menu">
	  <div class="colorbar"></div>
	  <div :class="menuListFlag?'mobile_menu_btn mobile_menu_btn1':'mobile_menu_btn'" @click.stop="showMenuList"></div>
	  <div class="mobile_menu_title"><p class="titleB">{{ titleText2 }}</p><p class="titleA">{{ titleText1 }}</p></div>
	  <img class="mobile_menu_logo" src="./assets/img/59bai.png" alt="" @click="goHome">
	</div>
	<ul class="mobile_menu_list">
	  <li v-for="(item,index) in menuList" :key="item.id" @click.stop="selectMenu(index)" :class="'menu_list_li'+index">
		<!--  <svg class="svg">
		    <path class="path-loop" d="M3,1A3,3,0,1,1,1,3,3,3,0,0,1,3,1"></path>
		  </svg> -->
	    <span>{{ item.text }}</span>
	  </li>
	</ul>
	<ul class="pc_menu_list">
		<li v-for="(item,index) in menuList" :key="item.id" @click.stop="selectMenu(index)" @mouseover="onmouseoverC(index)" @mouseout="onmouseroutC(index)" :class="'menu_list_li'+index" style="transform:translateX(-1000px);">
			<svg class="svg">
			  <path class="path-loop" :d="'M'+r+',2A'+r+','+r+',0,1,1,2,'+r+','+r+','+r+',0,0,1,'+r+',2'"></path>
			</svg>
			<span>{{ item.text }}</span>
		</li>
	</ul>
	<div class="router_view" @click.stop="closeMenuPc()">
		<router-view/>
	</div>
  </div>
</template>
<script>
	import $ from 'jquery';
	import anime from './assets/js/anime.min.js';
	var r = 11;
	if(document.body.clientWidth<1024){
		r = 10
	}else if(document.body.clientWidth<1280){
		r = 11
	}else if(document.body.clientWidth<1440){
		r = 12
	}else if(document.body.clientWidth<1680){
		r = 13
	}else{
		r = 14
	}
	export default {
	  name: 'App',
	  data(){
	  	return {
			r:11,
	  		menuListFlag:false,
	  		nowMenuListIndex:0,
			titleText1:'59号人民艺术',
			titleText2:'58号人民艺术',
	  		menuList:[
	  			{
	  				id:0,
	  				text:'首页',
					path:'/'
	  			},
	  			{
	  				id:1,
	  				text:'展览介绍',
					path:'secondary?sec_page_id=2'
	  			},
	  			{
	  				id:2,
	  				text:'品牌设计',
					path:'secondary?sec_page_id=1'
	  			},
	  			{
	  				id:3,
	  				text:'文旅规划',
					path:'secondary?sec_page_id=3'
	  			},
	  			{
	  				id:4,
	  				text:'景观色彩',
					path:'secondary?sec_page_id=4'
	  			},
	  			{
	  				id:5,
	  				text:'关于我们',
					path:'about'
	  			}
	  		],
			colorbarFlag:true,
			moduleColor:{},
			menuText:{},
			directionList:[],
			categoryObj:{
				1:'品牌设计',
				2:'展览介绍',
				3:'文旅规划',
				4:'景观色彩'
			},
			flag1:true,
			flag2:true,
			flag3:true,
			flag4:true,
			tTitleArr:[],
			tTitleArrIndex:-1,
			tColorArr:[],
			tColorArrIndex:-1,
			tProjectname:'',
			tMenuColor:'#000000'
		}
	  },
	  beforeMount() {
	  	this.r = r;
	  },
	  watch:{
	      $route(to,from){
			  let _this = this;
	        _this.closeMenuPc();
			if(to.path != '/'){
				window.removeEventListener("scroll", _this.handleScroll, true);
			}else{
				window.addEventListener('scroll', _this.handleScroll, true);
			}
			if(to.path != '/thridpage'){
				window.removeEventListener("scroll", _this.thridpageScroll, true);
				
			}
			if(to.path == '/'){//首页
				_this.toTitle(_this.menuText.title1);
				setTimeout(function(){
					_this.toColor(_this.moduleColor.module1Color);
				},800)
			}else if(to.path == '/secondary'){//二级页面
				var color;
				for(var i in _this.directionList){
					if(_this.categoryObj[to.query.sec_page_id] == _this.directionList[i].name){
						color = _this.directionList[i].menuColor;
					}
				}
				console.log(to.query);
				if(to.query.type == 1){
					_this.toTitle(_this.categoryObj[to.query.sec_page_id])
					setTimeout(function(){
						_this.toColor(color);
					},800)
				}else{
					setTimeout(function(){
						_this.toTitle(_this.categoryObj[to.query.sec_page_id])
						_this.toColor(color);
					},800)
				}
				
				
			}else if(to.path == '/about'){//关于我们
				_this.toTitle('关于我们')
				setTimeout(function(){
					_this.toColor(_this.moduleColor.module4Color);
				},800)
			}else if(to.path == '/thridpage'){//详情页
				_this.toThridpage();
			}
	      }
	  },
	  mounted() {
		  let _this = this;
		  _this.getData();
		  if(_this.$route.path == '/'){//首页
			window.addEventListener('scroll', this.handleScroll, true);
		  }
		  if(_this.$route.path == '/thridpage'){
			  setTimeout(function(){
				  _this.toThridpage();
			  },1000)
		  }
	  },
	  methods:{
		goHome(){
			this.$router.push({path:'/'})
		},
		toThridpage(){
			let _this = this;
			window.addEventListener('scroll', _this.thridpageScroll, true);
			_this.$axios({
			  method: 'get',
			  url: 'page_manager/thrid_page?id='+_this.$route.query.id,
			}).then(function(res) {
			  if(res.data.data.length > 0){
				  _this.tProjectname = res.data.data[0].projectname;
				  _this.tMenuColor = res.data.data[0].menuColor;
				  _this.toTitle(res.data.data[0].projectname);
				  console.log('templatees====',res.data.data[0].templatees);
				  for(var s in res.data.data[0].templatees){
					  _this.tTitleArr.push(res.data.data[0].templatees[s].title);
					  if(res.data.data[0].templatees[s].color.indexOf('#') == -1){
						  _this.tColorArr.push('#'+res.data.data[0].templatees[s].color);
					  }else{
						  _this.tColorArr.push(res.data.data[0].templatees[s].color);
					  }
				  }
				  setTimeout(function(){
				  	_this.toColor(res.data.data[0].menuColor);
				  },800)
			  }
			});
		},
		thridpageScroll(e){
			let _this = this;
			var moduleAll = document.getElementsByClassName('moduleAll');
			var arr111 = [];
			for(var i = 0;i<moduleAll.length;i++){
			  arr111.push(moduleAll[i].offsetTop);
			}
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			if(scrollTop <= 200 && _this.tTitleArrIndex != -1){
				_this.tTitleArrIndex = -1;
				_this.toTitle(_this.tProjectname);
				_this.toColor(_this.tMenuColor);
			}
			setTimeout(function(){
				for(var w = 0;w<arr111.length;w++){
					if(_this.tTitleArr[w] != ''){
						if(arr111[w]-300 < scrollTop && scrollTop < arr111[w]+300){
							if(_this.tTitleArrIndex != w){
								_this.tTitleArrIndex = w;
								_this.toTitle(_this.tTitleArr[w]);
								_this.toColor(_this.tColorArr[w]);
							}
						}
					}
					
				}
			},200)
			
		},
		handleScroll(e){
			let _this = this;
			_this.closeMenuPc();
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			let availHeight =  window.screen.availHeight
			var module1 = document.getElementById('home_main_title'); 
			var module2 = document.getElementById('swiper'); 
			var module3 = document.getElementById('direction_title'); 
			var module4 = document.getElementById('about');
			var domOffset1 = offset(module1);
			var domOffset2 = offset(module2);
			var domOffset3 = offset(module3);
			var domOffset4 = offset(module4);
		
			
			if(scrollTop < domOffset2.top-300){
				if(_this.flag1){
					_this.toColor(_this.moduleColor['module1Color']);
					setTimeout(function(){
						_this.toTitle(_this.menuText['title1']);
					},800)
					_this.flag1 = false;
					_this.flag2 = true;
					_this.flag3 = true;
					_this.flag4 = true;
				}
				
			}else if(scrollTop < domOffset3.top-300){
				if(_this.flag2){
					_this.flag1 = true;
					_this.flag2 = false;
					_this.flag3 = true;
					_this.flag4 = true;
					_this.toColor(_this.moduleColor['module2Color']);
					setTimeout(function(){
						_this.toTitle(_this.menuText['title2']);
					},800)
				}
				
			}else if(scrollTop < domOffset4.top-300){
				if(_this.flag3){
					_this.flag1 = true;
					_this.flag2 = true;
					_this.flag3 = false;
					_this.flag4 = true;
					_this.toColor(_this.moduleColor['module3Color']);
					setTimeout(function(){
						_this.toTitle(_this.menuText['title3']);
					},800)
				}
				
			}else if(scrollTop > domOffset4.top){
				if(_this.flag4){
					_this.flag1 = true;
					_this.flag2 = true;
					_this.flag3 = true;
					_this.flag4 = false;
					_this.toColor(_this.moduleColor['module4Color']);
					setTimeout(function(){
						_this.toTitle(_this.menuText['title4']);
					},800)
				}
			}
		},
		toColor(color){
			$('.colorbar').css('background',color);
			if(document.body.clientWidth > 750){
				$('.pc_menu_list').css('background',color);
				anime({
				  targets: '.colorbar',
				  bottom: '0',
				  height:'100vh',
				  easing: 'linear',
				  duration:300
				});
				setTimeout(function(){
					$('.mobile_menu').css('background',color);
					$('.colorbar').css({'bottom':'100%','height':'0'});
				},800)
			}else{
				$('.mobile_menu_list').css('background',color);
				anime({
				  targets: '.colorbar',
				  bottom: '0',
				  height:'2.74rem',
				  easing: 'linear',
				  duration:300
				});
				setTimeout(function(){
					$('.mobile_menu').css('background',color);
					$('.colorbar').css({'bottom':'100%','height':'0'});
				},800)
			}
			
		},
		toTitle(text){
			console.log(text);
			let _this = this;
			_this.titleText2 = text;
			anime({
			  targets: '.titleA',
			  top:'100%',
			  opacity:0,
			  easing: 'linear',
			  duration:500
			});
			anime({
			  targets: '.titleB',
			  top:'0',
			  opacity:'1',
			  easing: 'linear',
			  duration:500
			});
			setTimeout(function(){
				_this.titleText1 = text;
				$('.titleA').css({'top':'0','opacity':'1'});
				$('.titleB').css({'top':'-100%','opacity':'0'});
				console.log('执行了！！！')
			},800)
			
		},
		onmouseoverC(index){
			$('.menu_list_li'+ index+' .svg').css('display','inline-block').parent('li').siblings('li').children('.svg').css('display','none');
			anime({
				targets: '.menu_list_li'+ index +' .path-loop',
				strokeDashoffset: [anime.setDashoffset, 0],
				easing: 'easeInOutQuart',
				duration: 800,
			});
		},
		onmouseroutC(index){
			anime({
				targets: '.menu_list_li'+ index +' .path-loop',
				strokeDashoffset: [0,anime.setDashoffset],
				easing: 'easeInOutQuart',
				duration: 800,
			});
		},
	  	showMenuList(){
	  		let _this = this;
	  		_this.menuListFlag = !_this.menuListFlag;
	  		if(_this.menuListFlag){
	  			if(document.body.clientWidth > 750){
					anime({
					  targets: '.pc_menu_list',
					  width: '3.63rem',
					  easing: 'easeInOutQuart',
					  duration:800
					});
					anime({
					  targets: '.pc_menu_list li',
					  translateX:0,
					  delay: anime.stagger(50), // 每个元素的延迟增加100毫秒。
					  easing: 'linear',
					  duration:800
					});
					anime({
					  targets: '.titleA',
					  top:'-100%',
					  opacity:0,
					  easing: 'linear',
					  duration:500
					});
	  			}else{
	  				$('.mobile_menu_list').css('width','100%');
	  				$('.mobile_menu_list').slideDown('fast');
					anime({
					  targets: '.titleA',
					  top:'-100%',
					  opacity:0,
					  easing: 'linear',
					  duration:500
					});
	  			}
	  			
	  		}else{
	  			if(document.body.clientWidth > 750){
					_this.closeMenuPc();
					
	  			}else{
	  				$('.mobile_menu_list').css('width','100%');
	  				$('.mobile_menu_list').slideUp('fast');
					anime({
					  targets: '.titleA',
					  top:'0',
					  opacity:1,
					  easing: 'linear',
					  duration:500
					});
	  			}
				
	  		}
	  	},
		selectMenu(i){
			let _this = this;
			_this.nowMenuListIndex = i;
			if(document.body.clientWidth < 750){
				_this.onmouseoverC(i);
			}
			_this.menuListFlag = !_this.menuListFlag;
			if(document.body.clientWidth > 750){
				_this.closeMenuPc();
			}else{
				$('.mobile_menu_list').css('width','100%');
				$('.mobile_menu_list').slideUp('fast');
			}
			_this.$router.push({path:_this.menuList[i].path,query:{'type':1}});
		},
		closeMenuPc(){
			this.menuListFlag = false;
			anime({
				targets: '.pc_menu_list li',
				translateX: -1000,
				delay: anime.stagger(50, {direction: 'reverse'}), // 每个元素的延迟增加100毫秒。
				easing: 'linear',
				duration:800
			});
			anime({
			  targets: '.pc_menu_list',
			  width: '0',
			  easing: 'easeInOutQuart'
			});
			anime({
			  targets: '.titleA',
			  top:'0',
			  opacity:1,
			  easing: 'linear',
			  duration:500
			});
		},
		getData(){
			let _this = this;
			_this.$axios({
				method: 'get',
				url: 'page_manager/first_page',
			}).then(function(res) {
				console.log('res---------',res);
				if(res.data.code == 0){
					_this.moduleColor = res.data.data[0].body.moduleColor;
					_this.menuText = res.data.data[0].body.menuText;
					_this.directionList = res.data.data[0].body.directionList;
					var color;
					if(_this.$route.path == '/'){//首页
						color = res.data.data[0].body.moduleColor.module1Color;
						// _this.toTitle('59号人民艺术')
					}else if(_this.$route.path == '/secondary'){//二级页面
						for(var i in _this.directionList){
							if(_this.categoryObj[_this.$route.query.sec_page_id] == _this.directionList[i].name){
								color = _this.directionList[i].menuColor;
							}
						}
						_this.toTitle(_this.categoryObj[_this.$route.query.sec_page_id])
					}else if(_this.$route.path == '/about'){//关于我们
						color = res.data.data[0].body.moduleColor.module4Color;
						_this.toTitle('关于我们')
					}else if(_this.$route.path == '/thridpage'){//详情页
						_this.$axios({
						  method: 'get',
						  url: 'page_manager/thrid_page?id='+_this.$route.query.id,
						}).then(function(res) {
							console.log(res);
						  if(res.data.data.length > 0){
							  _this.toTitle(res.data.data[0].projectname)
							  color = res.data.data[0].menuColor;
						  }
						});
					}
					
					$('.colorbar').css('background',color);
					$('.pc_menu_list').css('background',color);
					$('.mobile_menu_list').css('background',color);
					$('.mobile_menu').css('background',color);
					
				}
			})
		},
	  }
	}
	function offset(element) {
	  var pos = { left: 0, top: 0 }
	  var parents = element.offsetParent
	
	  pos.left += element.offsetLeft
	  pos.top += element.offsetTop
	
	  while (parents && !/html|body/i.test(parents.tagName)) {
	    pos.left += parents.offsetLeft
	    pos.top += parents.offsetTop
	
	    parents = parents.offsetParent
	  }
	  return pos
	}
</script>
<style>
@import url("assets/css/reset.css");
@import url("assets/css/animate.min.css");
@import url("assets/css/style_xs.css");
@import url("assets/css/style_lg.css");


</style>
