<template>
	<div class="module-4">
		<div v-for="(item,index) in imgArr" :key="item.name"><video :src="item.videoUrl" v-if="'videoUrl' in item" muted="muted" autoplay="true" loop></video><img :src="item.url" v-else></div>
	</div>
</template>

<script>
	export default {
		name: 'moduleFour',
		props: ['imgArr'],
		methods:{
			
		},
	}
</script>

<style scoped="scoped">
	.module-4{
		width: 100%;
		position: relative;
		overflow: hidden;
	}
	.module-4 div:nth-child(1){
		max-width: 29.418%;
		width: 4.44rem;
		height: 3.76rem;
		float: left;
	}
	.module-4 div:nth-child(3){
		max-width: 29.418%;
		width: 4.44rem;
		height: 3.76rem;
		float: left;
	}
	.module-4 div:nth-child(2){
		max-width: 70.581%;
		width: 10.56rem;
		height: 7.48rem;
		float: right;
	}
	.module-4 img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.module-1 div video{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media screen and (min-width:751px){
		.module-4 div:nth-child(1){
			max-width: 29.418%;
			width: 4.109375rem;
			height:3.476562rem;
			float: left;
		}
		.module-4 div:nth-child(3){
			max-width: 29.418%;
			width: 4.109375rem;
			height: 3.476562rem;
			float: left;
		}
		.module-4 div:nth-child(2){
			max-width: 70.581%;
			width: 9.859375rem;
			height: 6.945312rem;
			float: right;
		}
		
	}
	
</style>
