<template>
	<div class="moduleAll">
		<module-one v-if="templates.template_id == 1" :imgArr="templates.images"></module-one>
		<module-two v-if="templates.template_id == 2" :imgArr="templates.images"></module-two>
		<module-three v-if="templates.template_id == 3" :imgArr="templates.images"></module-three>
		<module-four v-if="templates.template_id == 4" :imgArr="templates.images"></module-four>
	</div>
</template>

<script>
	import moduleOne from '@/components/moduleOne.vue'
	import moduleTwo from '@/components/moduleTwo.vue'
	import moduleThree from '@/components/moduleThree.vue'
	import moduleFour from '@/components/moduleFour.vue'
	export default {
		name: 'moduleAll',
		props: ['templates'],
		components: {
			moduleOne,
			moduleTwo,
			moduleThree,
			moduleFour
		},
		data(){
			return {
				
			}
		},
		methods:{
			
		},
	}
</script>

<style>
</style>
