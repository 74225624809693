<template>
	<!-- contact -->
	<div class="contact">
		<p class="contact_slogin">想象力，创造美好世界</p>
		<p class="contact_email">mochuangdesign@163.com</p>
		<div class="swiper-container1">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<ul class="contact_list">
						<li @click.stop="contact(item)" v-for="item in contactList" :key="item.name">
							<img :src="item.imgUrl" alt="">
						</li>
					</ul>
				</div>
			</div>
		</div>
		<img :src="nowTypeImg" alt="" class="nowTypeImg">
		<div class="shadow2" v-if="shadow2" @click="hideImg()"></div>
		<a id="call" class="call"></a>
	</div>
</template>

<script>
	import anime from '../assets/js/anime.min.js';
	export default {
		name: 'contact',
		data() {
			return {
				nowTypeImg: '',
				shadow2: false,
				contactList: [{
						name: 'weixin',
						imgUrl: require('../assets/img/weixin.png'),
						url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIxMjA1MjY5Mw==&scene=124#wechat_redirect'
					},
					{
						name: 'douyin',
						imgUrl: require('../assets/img/douyin.png'),
						url: 'https://v.douyin.com/egDRdW1/'
					},
					{
						name: 'weibo',
						imgUrl: require('../assets/img/weibo.png'),
						url: 'https://weibo.com/u/7269936254'
					},
					{
						name: 'dianhua',
						imgUrl: require('../assets/img/dianhua.png'),

					},
					{
						name: 'ins',
						imgUrl: require('../assets/img/ins.png'),
						url: 'https://instagram.com/no.59_art'
					},
					{
						name: 'bilibili',
						imgUrl: require('../assets/img/bilibili.png'),
						url: 'https://space.bilibili.com/1126276599'
					},
					{
						name: 'kuaishou',
						imgUrl: require('../assets/img/kuaishou.png'),
						url: 'https://v.kuaishou.com/e2aIQ6'
					},
					{
						name: 'xiaohongshu',
						imgUrl: require('../assets/img/xiaohongshu.png'),
						url: 'https://www.xiaohongshu.com/user/profile/6042034c000000000100499d'
					},
					{
						name: 'youtube',
						imgUrl: require('../assets/img/youtube.png'),
						url: 'https://www.youtube.com/channel/UCibnVgO8OCn0DDCVdHOdkuA'
					},
				]
			}
		},
		mounted() {

		},
		methods: {
			contact(data) {
				let _this = this;
				switch (data.name) {
					case 'weixin':
						// 判断是否为移动端运行环境
						if (/AppleWebKit.*Mobile/i.test(navigator.userAgent) || (
								/MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/
								.test(navigator.userAgent))) {
							if (window.location.href.indexOf("?mobile") < 0) {
								try {
									if (/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)) {
										// 判断访问环境是 Android|webOS|iPhone|iPod|BlackBerry 则加载以下
										window.open(data.url)
									} else {
										// 判断访问环境是 其他移动设备 则加载以下
										console.log('其他移动设备');
									}
								} catch (e) {}
							}
						} else {
							// 如果以上都不是，则加载以下
							console.log('非移动设备');
							_this.shadow2 = true;
							_this.nowTypeImg = require('../assets/img/img_code.png');
							anime({
								targets: '.nowTypeImg',
								width: '1.5rem',
								height: '1.5rem',
								left: '0.78125rem',
								top: '1.5rem',
								easing: 'easeInOutQuart',
								duration: 300
							});
						}
						
						break;
					case 'dianhua':
						
						var call = document.getElementById('call');
						var isMobilePlayer = 0;
						// 判断是否为移动端运行环境
						if (/AppleWebKit.*Mobile/i.test(navigator.userAgent) || (
								/MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/
								.test(navigator.userAgent))) {
							if (window.location.href.indexOf("?mobile") < 0) {
								try {
									if (/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)) {
										// 判断访问环境是 Android|webOS|iPhone|iPod|BlackBerry 则加载以下
										console.log('拨打电话');
										call.setAttribute('href', 'tel:13911445134');
										call.click();
									} else {
										// 判断访问环境是 其他移动设备 则加载以下
										console.log('其他移动设备');
									}
								} catch (e) {}
							}
						} else {
							// 如果以上都不是，则加载以下
							console.log('非移动设备');
						}
					break;
					default:
						window.open(data.url)
				}
			},
			hideImg() {
				this.shadow2 = false;
				anime({
					targets: '.nowTypeImg',
					width: '0',
					height: '0',
					left: '1.5rem',
					top: '2.25rem',
					easing: 'easeInOutQuart',
					duration: 300
				});
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.contact {
		position: relative;
	}

	.call {
		display: none;
	}

	@media screen and (min-width:751px) {
		.shadow2 {
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 100;
		}

		.nowTypeImg {
			width: 0;
			height: 0;
			margin: 0.5rem auto;
			position: absolute;
			top: 2.25rem;
			left: 1.5rem;
			z-index: 10;
		}
	}
</style>
