import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
Vue.config.productionTip = false;

function animateCss(element, animationName,times, callback) {
	const nodes = document.querySelectorAll(element)
	nodes.forEach((node => {
		if(times) node.style.setProperty('animation-duration', times, 'important');
		node.classList.add('animated', animationName)
		function handleAnimationEnd() {
			node.classList.remove('animated', animationName)
			node.removeEventListener('animationend', handleAnimationEnd)

			if (typeof callback === 'function') callback()
		}
		node.addEventListener('animationend', handleAnimationEnd)
	}))
}
axios.defaults.timeout = 10000;   // 超时时间
axios.defaults.baseURL = 'http://no59.art:8003/';  // 默认地址
Vue.prototype.animateCss = animateCss;
Vue.prototype.$axios = axios;

router.afterEach((to,from,next)=>{

　　　　window,scrollTo(0,0)

　　})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

