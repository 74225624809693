<template>
	<ul class="direction_list">
		<li class="direction_project" v-for="(item,index) in directionList" :key="index" @click="toS(item.name)">
			<div class="project_banner">
				<div :style="'background-image: url('+item.imgUrl+');'" ></div>
			</div>
			<div class="direction_text">
				<p class="project_category">{{ item.category }}</p>
				<p class="project_title">{{ item.name }}</p>
				<p class="project_synopsis">{{ item.introduction }}</p>
			</div>
		</li>
	</ul>
</template>

<script>
	import $ from 'jquery';
	
	export default{
		name:'direction',
		props:['directionList'],
		data(){
			return {
				nameMap:{
					'展览介绍':2,
					'品牌设计':1,
					'文旅规划':3,
					'景观色彩':4,
					
				}
			}
		},
		watch: {
		    directionList(val, value) {
				console.log(val)
				if(val != undefined){
					$(function(){
						$('.direction_project').mouseover(function(){
							$(this).css({'background-color': 'rgb(240,240,240)'}).children('.project_banner').css({'height':'2.8rem'}).children('div').css({'transform':'scale(1.1,1.1)'}).parent('.project_banner').siblings('.direction_text').css({'padding-bottom':'0.6rem'});
						}).mouseout(function(){
							$(this).css({'background-color': 'rgb(255,255,255)'}).children('.project_banner').css({'height':'2.6rem'}).children('div').css({'transform':'scale(1,1)'}).parent('.project_banner').siblings('.direction_text').css({'padding-bottom':'0.8rem'});
						})
					})
				}
				
		    }
		},
		mounted() {
			console.log(this.directionList);
		},
		methods:{
			toS(names){
				let _this = this;
				_this.$router.push({path:'/secondary',query:{sec_page_id:_this.nameMap[names],type:2}});
			}
		}
	}
</script>

<style>
	
</style>
