<template>
	<div class="aboutUs">
		<div class="about" @click="toDetails(19)">
			<p class="about_title" >团队介绍</p>
			<p class="about_message">{{ aboutData.teamIntroduction }}</p>
		</div>
		<div class="about" @click="toDetails(20)">
			<p class="about_title">活动与奖项</p>
			<p class="about_message">{{ aboutData.awards }}</p>
		</div>
		<div class="about" @click="toDetails(21)">
			<p class="about_title">联系我们</p>
			<p class="about_message">{{ aboutData.contactUs }}</p>
		</div>
		<div class="about" @click="toDetails(22)">
			<p class="about_title">加入59号</p>
			<p class="about_message">{{ aboutData.joinUs }}</p>
		</div>
		<contact></contact>
	</div>
</template>
<script>
import contact from '@/components/contact.vue'
export default {
  name: 'aboutUs',
  components: {
    contact
  },
  data(){
  	return {
		aboutData:{}
	}
  },
  mounted() {
  	this.getData();
  },
  methods:{
	  getData(){
	  	let _this = this;
	  	_this.$axios({
	  		method: 'get',
	  		url: 'page_manager/first_page',
	  	}).then(function(res) {
	  		console.log(res);
	  		_this.aboutData = res.data.data[0].body.about;
	  	})
	  },
	  toDetails(id){
		  let _this = this;
		  _this.$router.push({path:'/aboutDetails',query:{id:id}});
	  }
  }
}
</script>
<style scoped>
	.about{
		cursor: pointer;
	}
	.about:nth-child(odd){
		background-color: #ADADAD;
	}
	.about:nth-child(even){
		background-color: #ddd;
	}
</style>