<template>
	<div class="swiper">
		<iframe name="swiperPage" src="http://no59.art:8002/" width="100%" height="100%"></iframe>
	</div>
</template>

<script>
	export default {
		name: 'swiper',
		data() {
			return {

			}
		},
		mounted() {
			let _this = this;
			window.addEventListener('message',function(event){
				if(typeof(event.data) == 'number'){
					_this.$router.push({path:'/thridpage',query:{id:event.data}});
				}
			  })
		},
		methods: {

		}
	}
</script>

<style>
	iframe {
		border: none;
	}
</style>
