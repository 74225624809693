<template>
	<div class="module-1">
		<div v-for="(item,index) in imgArr" :key="item.name"><video :src="item.videoUrl" v-if="'videoUrl' in item" muted="muted" autoplay="true" loop></video><img :src="item.url" v-else></div>
	</div>
</template>

<script>
	export default {
		name:'moduleOne',
		props: ['imgArr'],
		mounted() {
			console.log('imgArr',this.imgArr)
		},
		methods:{
			
		},
	}
</script>

<style scoped="scoped">
	.module-1{
		width: 100%;
		position: relative;
		overflow: hidden;
	}
	.module-1 div{
		max-width: 100%;
		width: 15rem;
		height: 10.58rem;
		background-color: #EEEEEE;
		float:left;
	}
	.module-1 div img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.module-1 div video{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media screen and (min-width:751px){
		.module-1 div{
			width: 13.96875rem;
			height: 9.84375rem;
		}
	}
	
</style>
