import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import aboutUs from '../views/aboutUs.vue'
import thridpage from '../views/thridpage.vue'
import aboutDetails from '../views/aboutDetails.vue'

Vue.use(VueRouter)

 const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
	  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/thridpage',
    name: 'thridpage',
    component: thridpage
  },
  {
    path: '/aboutDetails',
    name: 'aboutDetails',
    component: aboutDetails
  },
  {
    path: '/secondary',
    name: 'Secondary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/secondary.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
